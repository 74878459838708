var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-layout",
    [
      _c(
        "a-button",
        { attrs: { id: "scroll-to-bottom" }, on: { click: _vm.goToBottom } },
        [_c("a-icon", { attrs: { type: "arrow-down" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "gx-custom-registration gx-main-content-wrapper",
          attrs: { id: "content" },
        },
        [
          _c("div", { staticClass: "gx-login-container" }, [
            _c(
              "div",
              { staticClass: "gx-login-content" },
              [
                _c("div", { staticClass: "login-brand gx-text-center" }, [
                  _c("img", {
                    staticStyle: { "max-height": "80px" },
                    attrs: {
                      alt: "SubsApp",
                      src: require("@/assets/regular-logo.png"),
                    },
                  }),
                ]),
                _c("div", { staticClass: "gx-login-header gx-text-center" }, [
                  _c("h1", { staticClass: "gx-login-title" }, [
                    _vm._v("Register with SubsApp"),
                  ]),
                ]),
                _vm.validationMsg && _vm.validationMsg.length
                  ? _c(
                      "div",
                      _vm._l(_vm.validationMsg, function (item, index) {
                        return _c("a-alert", {
                          key: index,
                          attrs: { type: "error", message: item, banner: "" },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _c(
                  "a-form",
                  {
                    staticClass: "gx-login-form gx-form-row0",
                    attrs: { form: _vm.form },
                  },
                  [
                    _c(
                      "a-form-item",
                      { staticClass: "gx-m-0", attrs: { label: "First Name" } },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.fields.first_name,
                              expression: "fields.first_name",
                            },
                          ],
                          attrs: { placeholder: "Your first name" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { staticClass: "gx-m-0", attrs: { label: "Last Name" } },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.fields.last_name,
                              expression: "fields.last_name",
                            },
                          ],
                          attrs: { placeholder: "Your last name" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { staticClass: "gx-m-0", attrs: { label: "Email" } },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.fields.email,
                              expression: "fields.email",
                            },
                          ],
                          attrs: { placeholder: "Email" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { staticClass: "gx-m-0", attrs: { label: "Password" } },
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.fields.password,
                                expression: "fields.password",
                              },
                            ],
                            attrs: {
                              placeholder: "Password",
                              type: _vm.showPassword ? "text" : "password",
                            },
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                slot: "suffix",
                                type: _vm.showPassword
                                  ? "eye"
                                  : "eye-invisible",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.togglePasswordVisibility(true)
                                },
                              },
                              slot: "suffix",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { label: "Confirm Password" } },
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.fields.c_password,
                                expression: "fields.c_password",
                              },
                            ],
                            attrs: {
                              placeholder: "Confirm Password",
                              type: _vm.showConfirmPassword
                                ? "text"
                                : "password",
                            },
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                slot: "suffix",
                                type: _vm.showConfirmPassword
                                  ? "eye"
                                  : "eye-invisible",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.togglePasswordVisibility()
                                },
                              },
                              slot: "suffix",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { staticClass: "gx-text-center" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              type: "primary",
                              block: "",
                              "html-type": "submit",
                              loading: _vm.loginLoading,
                            },
                            on: { click: _vm.handleForm },
                          },
                          [_vm._v("\n              Register\n            ")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "gx-text-center" }, [
                      _c(
                        "h6",
                        [
                          _vm._v(
                            "\n              Already have an account?\n              "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "gx-login-form",
                              attrs: { to: { path: "/login" } },
                            },
                            [
                              _vm._v(
                                "\n                Sign In\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "gx-text-center" },
                      [_c("router-link", { attrs: { to: "/" } })],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { attrs: { id: "page-bottom" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }